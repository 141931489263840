import {createI18n} from "vue-i18n";
import {nextTick} from "vue";
import {setErrorMap} from "zod";
import {makeZodI18nMap} from "zod-vue-i18n";

export const availableLocales = [
    {
        code: 'de-DE',
        name: 'Deutsch',
        flag: ''
    },
    {
        code: 'en-US',
        name: 'English',
        flag: ''
    }
]

export const i18n = createI18n({
    legacy: false,
    locale: 'en-US',
    fallbackLocale: 'en-US',
});

setErrorMap(makeZodI18nMap(i18n));

export const setLocale = async (locale: string) => {
    i18n.global.locale.value = locale;
    await loadLocaleMessages(locale);
}

export const loadLocaleMessages = async (locale: string) => {
    const messages = await import(`@/locales/${locale}.json`);
    i18n.global.setLocaleMessage(locale, messages.default);
    return nextTick();
}
