import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import {vuetify} from '@/plugins/vuetify';
import {pinia} from '@/plugins/pinia';

import VueApexCharts from "vue3-apexcharts";
import {i18n, setLocale} from "@/plugins/i18n";
import {useAuthStore} from "@/modules/authentication/stores/auth";
import * as Sentry from "@sentry/vue";

const app = createApp(App);

app.use(pinia);

const {registerRouting} = useAuthStore();
registerRouting(router);


Sentry.init({
  app,
  dsn: "https://1483638d6c1a0dbb8f399336e8c4623d@sentry.de.healthstudyclub.net/7",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api\.[a-zA-Z0-9.-]+\.hsc\.health/,],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router);
app.use(i18n);
app.use(vuetify);
app.use(VueApexCharts);


await setLocale('en-US');

app.mount('#app');





