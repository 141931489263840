import {defineStore} from "pinia";
import {useTheme} from "vuetify";
import {computed, watch} from "vue";
import {useLocalStorage} from "@vueuse/core";


export const useThemeStore = defineStore('theme', () => {

    const {global} = useTheme();

    const theme = useLocalStorage('hsp-theme', 'light');
    const isDarkTheme = computed(() => theme.value === 'dark');

    const toggle = () => {
        theme.value = isDarkTheme.value ? 'light' : 'dark';
    }

    const onThemeChange = (value: string) => {
        global.name.value = value;
    }

    watch(theme, onThemeChange, {immediate: true})

    return {isDarkTheme, toggle};

})
